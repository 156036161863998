import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {
  vendarData: [],
  modalVisible: false,
  fetching: false,
  pod_receivedData: [],
  save_Search_Data: '',
};

const CLOSE = 'CLOSE';
const SAVE_QUERY_PARAMS = 'SAVE_QUERY_PARAMS';
const FETCH_DATA = 'FETCH_DATA';
const POD_RECEIVED = 'POD_RECEIVED';
const DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED';
const TRIPS_SUMMARY = 'TRIPS_SUMMARY';
const ERROR = 'ERROR';
const SAVE_TRIP_CHARGES = 'SAVE_TRIP_CHARGES';
const SAVE_TRIP_CHARGES_COMPLETED = 'SAVE_TRIP_CHARGES_COMPLETED';
const SAVE_TRIP_CHARGES_FAILED = 'SAVE_TRIP_CHARGES_FAILED';

let saveVendorId = '';

export const openModal = (data) => {
  return async (dispatch) => {
    dispatch({
      type: data,
    });
  };
};

export const closeModal = () => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE,
    });
  };
};

export const updateQueryString = (search) => {
  return async (dispatch) => {
    dispatch({type: SAVE_QUERY_PARAMS, payload: search});
  };
};

export const vendorSearchCall = (saveQueryParams, search) => {
  return async (dispatch) => {
    let response = {};
    if (saveQueryParams) {
      dispatch({type: SAVE_QUERY_PARAMS, payload: search});
    }
    dispatch({type: FETCH_DATA});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchVendorSearchData(search),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: TRIPS_SUMMARY,
          payload: data.data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {
      dispatch({
        type: ERROR,
        payload: {error: err.message},
      });
    }
  };
};

export const podSearchCall = (search, newValue) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/get-pending-fnf-trips${search}`,
        data: {
          vendor_id: saveVendorId.id,
          pod_received: saveVendorId.pod_received,
          ...newValue,
        },
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: POD_RECEIVED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveReceivedData = (data1) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_TRIP_CHARGES});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/save-trip-charge`,
        data: data1,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SAVE_TRIP_CHARGES_COMPLETED,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SAVE_TRIP_CHARGES_COMPLETED,
          payload: {error: message},
        });
      }
    } catch (e) {
      console.log(e);
      dispatch({
        type: SAVE_TRIP_CHARGES_FAILED,
        payload: {error: e.message},
      });
    }
    return response;
  };
};

export const checkBoxData = (data1) => {
  let extraParams;

  if (data1.addQuery) {
    extraParams = data1.addQuery;
  } else {
    extraParams = '';
  }

  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/submit-fnf-requests${extraParams}`,
        data: data1.checkBoxData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: POD_RECEIVED,
          payload: {data: data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (Error) {
      console.log(Error);
    }
    return response;
  };
};

export const getpodData = (getVendorId) => {
  let extraParams;

  if (getVendorId.addQuery) {
    extraParams = getVendorId.addQuery;
  } else {
    extraParams = '';
  }

  saveVendorId = getVendorId;
  return async (dispatch) => {
    let response = {};

    try {
      dispatch({type: FETCH_DATA});
      dispatch({type: 'EMPTY_POD_RECEIVED'});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/get-pending-fnf-trips${extraParams}`,
        data: {
          vendor_id: getVendorId.id,
          pod_received: getVendorId.pod_received,
          annexure_number: getVendorId.annexure_number,
          trip_codes: getVendorId.trip_codes,
        },
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: POD_RECEIVED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: 'POD_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'POD_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const pendingTripsReducer = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'finance/get-pending-fnf-trips-summary',
      });
      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: TRIPS_SUMMARY,
          payload: data?.data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;

  console.log('action', action, payload);
  switch (type) {
    case TRIPS_SUMMARY:
      return {
        ...state,
        vendarData: payload,
        fetching: false,
      };

    case SAVE_QUERY_PARAMS:
      return {
        ...state,
        // save_Search_Data: payload,
        save_Search_Data: {
          ...state.pendingTrips?.save_Search_Data,
          ...payload,
        },
      };

    case 'POD_FAILED': {
      return {
        ...state,
        fetching: false,
      };
    }

    case 'EMPTY_POD_RECEIVED': {
      return {
        ...state,
        fetching: true,
        pod_receivedData: [],
      };
    }

    case POD_RECEIVED:
      return {
        ...state,
        fetching: false,
        pod_receivedData: payload,
        myFilter: payload.filters,
      };
    case 'OPEN':
      return {
        ...state,
        modalVisible: true,
      };
    case CLOSE:
      return {
        ...state,
        modalVisible: false,
      };
    // case 'VendorSearch':
    //   return {
    //     ...state,
    //     vendarData: payload,
    //   };

    case FETCH_DATA:
      return {
        ...state,
        fetching: true,
        pod_receivedData: [],
      };

    case ERROR:
      return {
        ...state,
        fetching: false,
      };

    case SAVE_TRIP_CHARGES:
      return {
        ...state,
        isDataSave: true,
      };
    case SAVE_TRIP_CHARGES_COMPLETED:
      return {
        ...state,
        isDataSave: false,
      };
    case SAVE_TRIP_CHARGES_FAILED:
      return {
        ...state,
        isDataSave: false,
      };
    default:
      return state;
  }
};
