import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {OwnVehicles} from '../../../helpers/Urls';

const initialState = {
  isFetchingData: false,
  happayProcessingData: {},
  happayProcessingDataIds: [],
  hasError: false,
  error: '',
};

export const HAPPAY_PROCESSING_REQUEST = 'HAPPAY_PROCESSING_REQUEST';
export const HAPPAY_PROCESSING_REQUEST_COMPLETED =
  'HAPPAY_PROCESSING_REQUEST_COMPLETED';
export const HAPPAY_PROCESSING_REQUEST_FAILED =
  'HAPPAY_PROCESSING_REQUEST_FAILED';

export const SAVE_HAPPAY_PROCESSING_REQUEST_DATA =
  'SAVE_HAPPAY_PROCESSING_REQUEST_DATA';
export const SAVE_HAPPAY_PROCESSING_REQUEST_DATA_COMPLETED =
  'SAVE_HAPPAY_PROCESSING_REQUEST_DATA_COMPLETED';
export const SAVE_HAPPAY_PROCESSING_REQUEST_DATA_FAILED =
  'SAVE_HAPPAY_PROCESSING_REQUEST_DATA_FAILED';

export const getHappayProcessingRequestData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: HAPPAY_PROCESSING_REQUEST});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: OwnVehicles.getHappayProcessingRequestURL(),
      });
      const {data} = response || {};
      dispatch({
        type: HAPPAY_PROCESSING_REQUEST_COMPLETED,
        payload: {...data},
      });
    } catch (err) {
      dispatch({
        type: HAPPAY_PROCESSING_REQUEST_FAILED,
        payload: {error: err},
      });
    }
    return response;
  };
};

export const saveHappayProcessingRequestData = (requestData) => {
  return async (dispatch) => {
    let res = {};
    try {
      dispatch({type: SAVE_HAPPAY_PROCESSING_REQUEST_DATA});
      res = await doRequest({
        method: REQUEST_TYPE.POST,
        url: OwnVehicles.saveHappayProcessingRequestURL(),
        data: requestData,
      });
      const {data} = res || {};
      dispatch({
        type: SAVE_HAPPAY_PROCESSING_REQUEST_DATA_COMPLETED,
        payload: {...data},
      });
    } catch (err) {
      dispatch({
        type: SAVE_HAPPAY_PROCESSING_REQUEST_DATA_FAILED,
        payload: {error: err},
      });
    }
  };
};

export default (state = initialState, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case HAPPAY_PROCESSING_REQUEST:
      return {
        ...state,
        isFetchingData: true,
        hasError: false,
        error: '',
      };
    case HAPPAY_PROCESSING_REQUEST_COMPLETED:
      return {
        ...state,
        isFetchingData: false,
        hasError: false,
        error: '',
        happayProcessingData: payload?.requests || {},
        happayProcessingDataIds: payload?.requests_ids || [],
      };
    case HAPPAY_PROCESSING_REQUEST_FAILED:
      return {
        ...state,
        isFetchingData: false,
        hasError: true,
        error: payload.error,
        happayProcessingData: {},
        happayProcessingDataIds: [],
      };
    case SAVE_HAPPAY_PROCESSING_REQUEST_DATA:
      return {
        ...state,
        isFetchingData: true,
        hasError: false,
        error: '',
      };
    case SAVE_HAPPAY_PROCESSING_REQUEST_DATA_COMPLETED:
      return {
        ...state,
        isFetchingData: false,
        hasError: false,
        error: '',
        happayProcessingData: payload?.requests || {},
        happayProcessingDataIds: payload?.requests_ids || [],
      };
    case SAVE_HAPPAY_PROCESSING_REQUEST_DATA_FAILED:
      return {
        ...state,
        isFetchingData: false,
        hasError: true,
        error: payload.error,
        happayProcessingData: {},
        happayProcessingDataIds: [],
      };
    default:
      return state;
  }
};
