import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';

export const EDIT_BOOKING = 'EDIT_BOOKING';
export const EDIT_BOOKING_COMPLETED = 'EDIT_BOOKING_COMPLETED';
export const EDIT_BOOKING_FAILED = 'EDIT_BOOKING_FAILED';

export const ACCEPT_BOOKING = 'ACCEPT_BOOKING';
export const ACCEPT_BOOKING_COMPLETED = 'ACCEPT_BOOKING_COMPLETED';
export const ACCEPT_BOOKING_FAILED = 'ACCEPT_BOOKING_FAILED';

const INITIAL_STATE = {isFetching: false};

export const acceptBooking = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ACCEPT_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.acceptBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: ACCEPT_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ACCEPT_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ACCEPT_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editBooking = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.editBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: EDIT_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_BOOKING_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case EDIT_BOOKING:
    case ACCEPT_BOOKING:
      return {...state, isFetching: true};
    case EDIT_BOOKING_COMPLETED:
    case ACCEPT_BOOKING_COMPLETED:
      return {...state, isFetching: false};
    case EDIT_BOOKING_FAILED:
    case ACCEPT_BOOKING_FAILED:
      return {...state, isFetching: false, error: payload?.error};
    default:
      return state;
  }
};
