import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tracking} from '../../../helpers/Urls';
import {close} from '../../../modules/modal';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  driverUploadsIds: [],
  driverUploads: {},
  docTypeIds: [],
  docTypes: {},
  driverLogsCount: 0,
  binCount: 0,
  driverUploadsCount: 0,
  team_type_ids: [],
  team_type: {},
  search: '',
  resetPage: false,
};

const FETCH_DRIVER_UPLOADS_DETAILS = 'FETCH_DRIVER_UPLOADS_DETAILS';
const FETCH_DRIVER_UPLOADS_DETAILS_COMPLETED =
  'FETCH_DRIVER_UPLOADS_DETAILS_COMPLETED';
const FETCH_DRIVER_UPLOADS_DETAILS_FAILED =
  'FETCH_DRIVER_UPLOADS_DETAILS_FAILED';

export const EDIT_DRIVER_UPLOAD = 'EDIT_DRIVER_UPLOAD';
export const EDIT_DRIVER_UPLOAD_COMPLETED = 'EDIT_DRIVER_UPLOAD_COMPLETED';
export const EDIT_DRIVER_UPLOAD_FAILED = 'EDIT_DRIVER_UPLOAD_FAILED';

export const CHECK_DRIVER_LICENSE = 'CHECK_DRIVER_LICENSE';
export const CHECK_DRIVER_LICENSE_COMPLETED = 'CHECK_DRIVER_LICENSE_COMPLETED';
export const CHECK_DRIVER_LICENSE_FAILED = 'CHECK_DRIVER_LICENSE_FAILED';

const RESET_PAGE = 'RESET_PAGE';

export const fetchDriverUploadsDetails = (
  page = 1,
  resetPage = true,
  binData,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_UPLOADS_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchDriverUploadsDetailsURL(page, binData),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: FETCH_DRIVER_UPLOADS_DETAILS_COMPLETED,
          payload: {...data},
        });
        dispatch(close());
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_UPLOADS_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_UPLOADS_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const setNewData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_DRIVER_UPLOADS_DETAILS_COMPLETED,
      payload: {...data},
    });
  };
};

export const editDriverUpload = (requestData = {}, params) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DRIVER_UPLOAD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.editDriverUploadURL(params),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_DRIVER_UPLOAD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_DRIVER_UPLOAD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DRIVER_UPLOAD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const checkDriverLicense = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_DRIVER_LICENSE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.checkDriverLicenseURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CHECK_DRIVER_LICENSE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: CHECK_DRIVER_LICENSE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_DRIVER_LICENSE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_DRIVER_UPLOADS_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_DRIVER_UPLOADS_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        driverUploadsIds: payload?.driver_uploads_list,
        driverUploads: payload?.driver_uploads,
        docTypes: payload?.doc_types,
        docTypeIds: payload?.doc_type_ids,
        driverUploadsCount: payload?.count?.uploads,
        driverLogsCount: payload?.count?.logs,
        binCount: payload?.count.bin,
        team_type_ids: payload.team_type_ids,
        team_type: payload.team_type,
        search: payload.query_string,
      };
    case FETCH_DRIVER_UPLOADS_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        driverUploadsIds: [],
        driverUploads: {},
        docTypes: {},
        docTypeIds: [],
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
