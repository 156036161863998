import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {
  advanceModal: false,
  loading: false,
  downloadingProcessData: false,
  advanceProcessRequestData: null,
  advancePendingRequestData: null,
  clearSearchInputField: false,
};

const DOWNLOAD_PROCESS_DATA = 'DOWNLOAD_PROCESS_DATA';
const DOWNLOAD_PROCESS_DATA_COMPLETED = 'DOWNLOAD_PROCESS_DATA_COMPLETED';
const DOWNLOAD_PROCESS_DATA_FAILED = 'DOWNLOAD_PROCESS_DATA_FAILED';
const CLEAR_SEARCH_INPUT = 'CLEAR_SEARCH_INPUT';

export const clearSearchInput = (data) => ({
  type: CLEAR_SEARCH_INPUT,
  payload: data,
});

export const sendUtrData = (sendData) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/update-utr',
        data: sendData,
      });
      const {data, status} = response || {};
      if (status) {
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

//GET request
export const getSearchData = (is_new, search = '', bodyData = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'LOADING'});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url:
          search && search?.length > 0
            ? `finance/process-advance?is_new=${is_new}${search}`
            : `finance/process-advance?is_new=${is_new}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: is_new ? 'ADVANCE_PENDING_REQUEST' : 'ADVANCE_PROCESS_REQUEST',
          payload: data,
          params: bodyData,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const downloadProcessData = (is_new, sendData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_PROCESS_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/process-advance?is_new=${is_new}`,
        data: sendData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_PROCESS_DATA_COMPLETED,
          // payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_PROCESS_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_PROCESS_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const openAdvanceModals = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADVANCE_MODAL_OPEN',
    });
  };
};

export const closeAdvanceModals = () => {
  return async (dispatch) => {
    dispatch({
      type: 'ADVANCE_MODAL_CLOSE',
    });
  };
};

export const getFilterDataRequest = (is_new, url) => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'SAVE_MY_PROCESS_REQUEST', payload: url});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,

        url: `finance/process-advance?is_new=${is_new}${url}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: is_new ? 'ADVANCE_PENDING_REQUEST' : 'ADVANCE_PROCESS_REQUEST',
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const savePendingFilterData = (url) => {
  return async (dispatch) => {
    dispatch({type: 'SAVE_MY_PENDING_URL', payload: url});
  };
};

export const saveProcessFilterData = (url) => {
  return async (dispatch) => {
    dispatch({type: 'SAVE_MY_PROCESS_REQUEST_FILTER', payload: url});
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
    case CLEAR_SEARCH_INPUT:
      return {
        ...state,
        clearSearchInputField: payload,
      };
    case 'ADVANCE_MODAL_OPEN':
      return {
        ...state,
        advanceModal: true,
      };
    case 'ADVANCE_MODAL_CLOSE':
      return {
        ...state,
        advanceModal: false,
      };
    case 'ADVANCE':
      return {
        ...state,
        advanceProcessData: payload,
      };

    case 'SAVE_MY_PENDING_URL':
      return {
        ...state,
        currentPendingFilterData: payload,
      };

    case 'SAVE_MY_PROCESS_REQUEST':
      return {
        ...state,
        currentProcessFilterData: payload,
        loading: true,
      };

    case 'ADVANCE_PROCESS_REQUEST':
      return {
        ...state,
        advanceProcessRequestData: payload,
        filters: payload.filters,
        countPending: payload.new_count,
        countProcess: payload.process_count,
        loading: false,
      };

    case 'ADVANCE_PENDING_REQUEST':
      return {
        ...state,
        advancePendingRequestData: payload,
        filters: payload.filters,
        countPending: payload.new_count,
        countProcess: payload.process_count,
        loading: false,
      };
    case 'LOADING':
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_PROCESS_DATA:
      return {
        ...state,
        downloadingProcessData: true,
        error: '',
      };
    case DOWNLOAD_PROCESS_DATA_COMPLETED:
      return {
        ...state,
        downloadingProcessData: false,
        error: payload.error,
      };
    case DOWNLOAD_PROCESS_DATA_FAILED:
      return {
        ...state,
        downloadingProcessData: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
