import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  gpsIds: [],
  recentIds: [],
  counts: {},
  vendor_filter_data: [],
  paymentMappingData: [],
  billDetails: [],
  payoutHistoryData: [],
  phFetching: false,
  directPayoutData: [],
  dpFetching: false,

  directPhFetching: false,
  directPayoutHistoryData: [],

  directPhFetchingFilter: false,
  directPayoutHistoryDataFilter: [],
};

const FETCH_DATA = 'FETCH_DATA';
const FETCH_PH_DATA = 'FETCH_PH_DATA';
const PH_DATA = 'PH_DATA';
const FETCH_PH_DATA_FAILED = 'FETCH_PH_DATA_FAILED';

const DIRECT_FETCH_PH_DATA = 'DIRECT_FETCH_PH_DATA';
const DIRECT_PH_DATA = 'DIRECT_PH_DATA';
const DIRECT_PH_DATA_FAILED = 'DIRECT_PH_DATA_FAILED';

const DIRECT_FETCH_PH_DATA_FILTER = 'DIRECT_FETCH_PH_DATA_FILTER';
const DIRECT_PH_DATA_FILTER = 'DIRECT_PH_DATA_FILTER';
const DIRECT_PH_DATA_FILTER_FAILED = 'DIRECT_PH_DATA_FILTER_FAILED';

const FETCH_DP_DATA = 'FETCH_DP_DATA';
const DP_DATA = 'DP_DATA';
const DP_DATA_FAILED = 'DP_DATA_FAILED';

const FETCH_DP_POST_DATA = 'FETCH_DP_POST_DATA';
const DP_POST_DATA = 'DP_POST_DATA';
const BILLDETAILS = 'BILLDETAILS';
const PAYMENT_DATA = 'PAYMENT_DATA';
const FETCH_SUBMIT_POST_DATA = 'FETCH_SUBMIT_POST_DATA';
const SUBMIT_POST_DATA = 'SUBMIT_POST_DATA';

// ADVANCE_PROCESS

export const openMyModal = (data) => {
  return async (dispatch) => {
    dispatch({
      type: data,
    });
  };
};

export const postDirectPayout = (_data, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DP_POST_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/additional-charges-payouts`,
        data: _data,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DP_POST_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const submitPayout = (_data, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_SUBMIT_POST_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/validate-vpa`,
        data: _data,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SUBMIT_POST_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const getDirectPayout = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DP_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: id
          ? `finance/additional-charges-payouts?trip_id=${id}`
          : 'finance/additional-charges-payouts',
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DP_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DP_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DP_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// export const getPayoutHistory = (id) => {
//   return async (dispatch) => {
//     let response = {};
//     try {
//       dispatch({type: FETCH_PH_DATA});
//       response = await doRequest({
//         method: REQUEST_TYPE.GET,
//         url: `finance/payout-history?trip_id=${id}`,
//       });
//       const {data, status} = response || {};
//       if (status) {
//         dispatch({
//           type: PH_DATA,
//           payload: data,
//         });
//       } else {
//         const {message = 'Service Error'} = response || {};
//       }
//     } catch (err) {}
//     return response;
//   };
// };

export const getPayoutHistory = (value, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_PH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/payout-history?direct_payout=${value}&trip_id=${id}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: PH_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_PH_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PH_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getDirectPayoutHistory = (value, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DIRECT_FETCH_PH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/payout-history?direct_payout=${value}&vpa=${id}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DIRECT_PH_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DIRECT_PH_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DIRECT_PH_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getDirectPayoutHistoryFilter = (value, id, search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DIRECT_FETCH_PH_DATA_FILTER});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        // url: `finance/payout-history?direct_payout=${value}&vpa=${id}`,
        url: `finance/payout-history?direct_payout=${value}&vpa=${id}&status=${search}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DIRECT_PH_DATA_FILTER,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DIRECT_PH_DATA_FILTER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DIRECT_PH_DATA_FILTER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getPaymentData = (id) => {
  return async (dispatch) => {
    let response = {};
    let if_Id = id ? `?id=${id}` : '';
    try {
      dispatch({type: FETCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/get-advance-payment/${if_Id}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: PAYMENT_DATA,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const getBillDetails = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/get-bill-details?bill_id=${id}`,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: BILLDETAILS,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const postPaymentMapping = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/get-advance-payment',
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_DP_POST_DATA:
      return {
        ...state,
        dpPostFetching: true,
      };
    case DP_POST_DATA:
      return {
        ...state,
        dpPostFetching: false,
        directPayoutPostData: payload,
      };

    //...........Submit API..........

    case FETCH_SUBMIT_POST_DATA:
      return {
        ...state,
        // dpPostFetching: true,
      };
    case SUBMIT_POST_DATA:
      return {
        ...state,
        dpPostFetching: false,
        // directPayoutPostData: payload,
      };
    //...........Submit API..........

    case FETCH_DP_DATA:
      return {
        ...state,
        dpFetching: true,
      };
    case DP_DATA:
      return {
        ...state,
        dpFetching: false,
        directPayoutData: payload,
      };

    case DP_DATA_FAILED:
      return {
        ...state,
        dpFetching: false,
        error: payload.error,
      };

    case FETCH_PH_DATA:
      return {
        ...state,
        phFetching: true,
      };
    case PH_DATA:
      return {
        ...state,
        phFetching: false,
        payoutHistoryData: payload,
      };
    case FETCH_PH_DATA_FAILED:
      return {
        ...state,
        phFetching: false,
        error: payload.error,
      };

    case DIRECT_FETCH_PH_DATA:
      return {
        ...state,
        directPhFetching: true,
      };
    case DIRECT_PH_DATA:
      return {
        ...state,
        directPhFetching: false,
        directPayoutHistoryData: payload,
      };
    case DIRECT_PH_DATA_FAILED:
      return {
        ...state,
        directPhFetching: false,
        error: payload.error,
      };

    case DIRECT_FETCH_PH_DATA_FILTER:
      return {
        ...state,
        directPhFetchingFilter: true,
      };
    case DIRECT_PH_DATA_FILTER:
      return {
        ...state,
        directPhFetchingFilter: false,
        directPayoutHistoryDataFilter: payload,
      };
    case DIRECT_PH_DATA_FILTER_FAILED:
      return {
        ...state,
        directPhFetchingFilter: false,
        error: payload.error,
      };

    case FETCH_DATA:
      return {
        ...state,
        fetching: true,
      };
    case PAYMENT_DATA:
      return {
        ...state,
        fetching: false,
        paymentMappingData: payload,
      };

    case BILLDETAILS:
      return {
        ...state,
        billDetails: payload,
      };

    default:
      return state;
  }
};
