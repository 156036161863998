import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Trips} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  haltLocations: [],
  haltTotal: 0,
  isDownloading: false,
  downloadUrl: '',
};

const FETCH_HALT_DURATIONS = 'FETCH_HALT_DURATIONS';
const FETCH_HALT_DURATIONS_COMPLETED = 'FETCH_HALT_DURATIONS_COMPLETED';
const FETCH_HALT_DURATIONS_FAILED = 'FETCH_HALT_DURATIONS_FAILED';

const DOWNLOAD_HALT_DURATIONS = 'DOWNLOAD_HALT_DURATIONS';
const DOWNLOAD_HALT_DURATIONS_COMPLETED = 'DOWNLOAD_HALT_DURATIONS_COMPLETED';
const DOWNLOAD_HALT_DURATIONS_FAILED = 'DOWNLOAD_HALT_DURATIONS_FAILED';

export const fetchHaltDurations = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_HALT_DURATIONS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchHaltDurationsURL(tripId),
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: FETCH_HALT_DURATIONS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_HALT_DURATIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_HALT_DURATIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const downloadHaltDurations = (tripId, currentDuration) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_HALT_DURATIONS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.downloadHaltDurationsURL(tripId, currentDuration),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: DOWNLOAD_HALT_DURATIONS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_HALT_DURATIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_HALT_DURATIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_HALT_DURATIONS:
      return {...state, isFetching: true};
    case FETCH_HALT_DURATIONS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        haltLocations: payload.halts_list,
        haltTotal: payload.halts_total,
      };
    case FETCH_HALT_DURATIONS_FAILED:
      return {...state, isFetching: false};

    case DOWNLOAD_HALT_DURATIONS:
      return {...state, isDownloading: true};
    case DOWNLOAD_HALT_DURATIONS_COMPLETED:
      return {
        ...state,
        isDownloading: false,
        downloadUrl: payload.url,
      };
    case DOWNLOAD_HALT_DURATIONS_FAILED:
      return {...state, isDownloading: false};

    default:
      return state;
  }
};
