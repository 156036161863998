import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {FnF} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isRequesting: false,
  hasError: false,
  error: '',
  driverIds: [],
  driverCount: 0,
  tripIds: [],
  search: '',
  resetPage: false,
  drivers: {},
  total: 0,
  driver_trips: {},
};

const FETCH_FANDF_DRIVER_DETAILS = 'FETCH_FANDF_DRIVER_DETAILS';
const FETCH_FANDF_DRIVER_DETAILS_COMPLETED =
  'FETCH_FANDF_DRIVER_DETAILS_COMPLETED';
const FETCH_FANDF_DRIVER_DETAILS_FAILED = 'FETCH_FANDF_DRIVER_DETAILS_FAILED';

const FETCH_DRIVER_TRIPS = 'FETCH_DRIVER_TRIPS';
const FETCH_DRIVER_TRIPS_COMPLETED = 'FETCH_DRIVER_TRIPS_COMPLETED';
const FETCH_DRIVER_TRIPS_FAILED = 'FETCH_DRIVER_TRIPS_FAILED';

const DOWNLOAD_FANDF = 'DOWNLOAD_FANDF';
const DOWNLOAD_FANDF_COMPLETED = 'DOWNLOAD_FANDF_COMPLETED';
const DOWNLOAD_FANDF_FAILED = 'DOWNLOAD_FANDF_FAILED';

const GET_FNF_DATA = 'GET_FNF_DATA';
const GET_FNF_DATA_COMPLETED = 'GET_FNF_DATA_COMPLETED';
const GET_FNF_DATA_FAILED = 'GET_FNF_DATA_FAILED';

const SAVE_FNF_DATA = 'SAVE_FNF_DATA';
const SAVE_FNF_DATA_COMPLETED = 'SAVE_FNF_DATA_COMPLETED';
const SAVE_FNF_DATA_FAILED = 'SAVE_FNF_DATA_FAILED';

const APPROVE_FNF = 'APPROVE_FNF';
const APPROVE_FNF_COMPLETED = 'APPROVE_FNF_COMPLETED';
const APPROVE_FNF_FAILED = 'APPROVE_FNF_FAILED';

const SEND_FOR_APPROVAL = 'SEND_FOR_APPROVAL';
const SEND_FOR_APPROVAL_COMPLETED = 'SEND_FOR_APPROVAL_COMPLETED';
const SEND_FOR_APPROVAL_FAILED = 'SEND_FOR_APPROVAL_FAILED';

const RESET_PAGE = 'RESET_PAGE';

const RESET_DRIVER_FILTER = 'RESET_DRIVER_FILTER';

export const resetFilters = () => {
  return (dispatch) => {
    dispatch({type: RESET_DRIVER_FILTER});
  };
};

export const fetchDriverRecords = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_FANDF_DRIVER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: FnF.fetchDriverRecords(page, search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_FANDF_DRIVER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_FANDF_DRIVER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_FANDF_DRIVER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadFnFReport = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_FANDF});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: FnF.downloadFandFReportURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_FANDF_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_FANDF_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_FANDF_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDriverTrips = (driverId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: FnF.fetchDriverTripsURL(driverId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_DRIVER_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getFnFData = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_FNF_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: FnF.fetchFnFDataURL(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_FNF_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_FNF_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_FNF_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const saveFnFData = (tripId, requestData) => {
  console.log('save fnf data called', tripId, requestData);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_FNF_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: FnF.fetchFnFDataURL(tripId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SAVE_FNF_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SAVE_FNF_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_FNF_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveFnF = (tripId, requestData) => {
  console.log('save fnf data called', tripId, requestData);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_FNF});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: FnF.approveFnFURL(tripId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: APPROVE_FNF_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: APPROVE_FNF_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPROVE_FNF_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const sendForApproval = (driverId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_FOR_APPROVAL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: FnF.sendForApprovalURL(driverId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SEND_FOR_APPROVAL_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEND_FOR_APPROVAL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_FOR_APPROVAL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setUpdatedDriverTrips = (state, payload = {}) => {
  const {driver_trips} = payload;
  if (driver_trips) {
    const oldRoutes = {...state.driver_trips};
    const newRoutes = {...driver_trips};
    const updatedState = {...oldRoutes, ...newRoutes};
    console.log('merged route tats', updatedState, oldRoutes, newRoutes);
    return {...state, isFetching: false, driver_trips: updatedState};
  } else return state;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_FANDF_DRIVER_DETAILS:
    case FETCH_DRIVER_TRIPS:
    case GET_FNF_DATA:
      return {
        ...state,
        isRequesting: true,
        hasError: false,
        error: '',
      };
    case FETCH_FANDF_DRIVER_DETAILS_COMPLETED:
      console.log(' payload.driver_count', payload);
      return {
        ...state,
        isRequesting: false,
        hasError: false,
        error: '',
        drivers: payload?.fnf_drivers,
        driverIds: payload?.fnf_driver_ids,
        driverCount: payload?.total_count,
        total: payload?.total_count,
        search: payload.query_string,
        fnf_driver_names: payload?.fnf_driver_names,
        fnf_driver_licences: payload?.fnf_driver_licences,
      };
    case FETCH_FANDF_DRIVER_DETAILS_FAILED:
    case FETCH_DRIVER_TRIPS_FAILED:
    case GET_FNF_DATA_FAILED:
      return {
        ...state,
        isRequesting: false,
        hasError: true,
        error: payload.error,
        driverIds: [],
        drivers: {},
        tripIds: [],
        driver_trips: {},
      };
    case FETCH_DRIVER_TRIPS_COMPLETED:
      return {
        ...state,
        hasError: true,
        isRequesting: false,
        error: payload,
        tripIds: payload?.driver_trip_ids,
        driver_trips: payload?.driver_trips,
      };
    case GET_FNF_DATA_COMPLETED:
      return {
        ...state,
        hasError: false,
        isRequesting: false,
        error: '',
      };
    case DOWNLOAD_FANDF:
    case SEND_FOR_APPROVAL:
    case SAVE_FNF_DATA:
    case APPROVE_FNF:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case DOWNLOAD_FANDF_COMPLETED:
    case SAVE_FNF_DATA_COMPLETED:
    case APPROVE_FNF_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case DOWNLOAD_FANDF_FAILED:
    case SEND_FOR_APPROVAL_FAILED:
    case SAVE_FNF_DATA_FAILED:
    case APPROVE_FNF_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case SEND_FOR_APPROVAL_COMPLETED:
      return setUpdatedDriverTrips(state, payload);
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};
    case RESET_DRIVER_FILTER:
      return {...state, search: ''};
    default:
      return state;
  }
};
