import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {OwnVehicles} from '../../../helpers/Urls';

const INITIAL_STATE = {
  hasError: false,
  error: '',
  uploadingFastTag: false,
  uploadingDiesel: false,
  missingTrips: undefined,
};

const UPLOAD_FASTAG = 'UPLOAD_FASTAG';
const UPLOAD_FASTAG_COMPLETED = 'UPLOAD_FASTAG_COMPLETED';
const UPLOAD_FASTAG_FAILED = 'UPLOAD_FASTAG_FAILED';

const UPLOAD_DIESEL = 'UPLOAD_DIESEL';
const UPLOAD_DIESEL_COMPLETED = 'UPLOAD_DIESEL_COMPLETED';
const UPLOAD_DIESEL_FAILED = 'UPLOAD_DIESEL_FAILED';

const RESET_STORE = 'RESET_STORE';

export const uploadFastTag = (requestData, isManual = false) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_FASTAG});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: isManual
          ? OwnVehicles.uploadFastTagManualURL()
          : OwnVehicles.uploadFastTagURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: UPLOAD_FASTAG_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: UPLOAD_FASTAG_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: UPLOAD_FASTAG_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const uploadDiesel = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_DIESEL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: OwnVehicles.uploadDieselURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: UPLOAD_DIESEL_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: UPLOAD_DIESEL_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: UPLOAD_DIESEL_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

const handleUpload = (state, payload) => {
  const isError = payload.missing_trips.is_error;
  let missingTrips = undefined;
  if (isError) {
    missingTrips = payload.missing_trips;
  }
  return {...state, uploadingFastTag: false, missingTrips};
};

const handleDieselUpload = (state, payload) => {
  const isError = payload.missing_booking.is_error;
  let missingBookings = undefined;
  if (isError) {
    missingBookings = payload.missing_booking;
  }
  return {...state, uploadingDiesel: false, missingBookings};
};

export const resetPage = () => {
  return async (dispatch) => {
    dispatch({type: RESET_STORE});
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case UPLOAD_FASTAG:
      return {...state, uploadingFastTag: true};
    case UPLOAD_FASTAG_COMPLETED:
      return handleUpload(state, payload);
    case UPLOAD_FASTAG_FAILED:
      return {...state, uploadingFastTag: false};

    case UPLOAD_DIESEL:
      return {...state, uploadingDiesel: true};
    case UPLOAD_DIESEL_COMPLETED:
      return handleDieselUpload(state, payload);
    case UPLOAD_DIESEL_FAILED:
      return {...state, uploadingDiesel: false};

    case RESET_STORE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
